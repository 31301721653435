import {autocompletion, closeBrackets, closeBracketsKeymap, completionKeymap} from '@codemirror/autocomplete';
import {bracketMatching, foldGutter, foldKeymap, indentOnInput} from '@codemirror/language';
import {lintKeymap} from '@codemirror/lint';
import {highlightSelectionMatches, searchKeymap} from '@codemirror/search';

import {
    crosshairCursor,
    dropCursor,
    highlightActiveLine,
    highlightActiveLineGutter,
    lineNumbers,
    rectangularSelection
} from '@codemirror/view';

import {tags as t} from '@lezer/highlight';
import {loadLanguage} from '@uiw/codemirror-extensions-langs';
import createTheme from '@uiw/codemirror-themes';

import CodeMirror, {EditorView} from '@uiw/react-codemirror';
import classNames from 'classnames';
import {useMemo} from 'react';
import {classPrefix, createStylesSelector} from '~/lib';

import classes from './Code.module.css';
import {CodeExtensions, CodeProps} from '~/@types/components/common/CodeProps';
import {CreateThemeOptions} from '@uiw/codemirror-themes/src';

// noinspection JSUnusedGlobalSymbols
const extensionFunctions = {
    lineNumbers,
    highlightActiveLineGutter,
    foldGutter,
    dropCursor,
    indentOnInput,
    bracketMatching,
    closeBrackets,
    autocompletion,
    rectangularSelection,
    crosshairCursor,
    highlightActiveLine,
    highlightSelectionMatches,
    closeBracketsKeymap,
    searchKeymap,
    foldKeymap,
    completionKeymap,
    lintKeymap,
};

function Code({
    editable = false,
    readOnly = false,
    theme: propsTheme = 'default',
    children,
    value,
    language = 'shell',
    setup = {
        lineNumbers: true,
        highlightActiveLineGutter: true,
        foldGutter: true,
        dropCursor: false,
        allowMultipleSelections: false,
        indentOnInput: true,
        bracketMatching: true,
        closeBrackets: true,
        autocompletion: true,
        rectangularSelection: false,
        crosshairCursor: false,
        highlightActiveLine: false,
        highlightSelectionMatches: false,
        closeBracketsKeymap: false,
        searchKeymap: false,
        foldKeymap: false,
        completionKeymap: false,
        lintKeymap: false,
        tabSize: 4,
        lineWrapping: true,
    },
    name: propsName,
    className: propsClassName,
    onChange: propsOnChange,
    onFocus: propsOnFocus,
    onBlur: propsOnBlur,
    onCreateEditor: propsOnCreateEditor,
    extensions: propsExtensions,
    onUpdate: propsOnUpdate,
    classes: propsClasses,
    styles: propsStyles,
}: CodeProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const themeName = typeof propsTheme === 'string' ? propsTheme : 'custom';

    const {
        theme: propsThemeName,
        settings: propsThemeSettings,
        styles: propsThemeStyles
    }: CreateThemeOptions = typeof propsTheme === 'object' ? propsTheme : {theme: 'dark', settings: {}, styles: []};

    const theme = createTheme({
        theme: propsThemeName || 'dark',
        settings: {
            background: 'var(--cm-color-background)',
            foreground: 'var(--cm-color-foreground)',
            caret: 'var(--cm-color-caret)',
            selection: 'var(--cm-color-selection)',
            selectionMatch: 'var(--cm-color-selection-match)',
            gutterBackground: 'var(--cm-color-gutter-background)',
            gutterForeground: 'var(--cm-color-gutter-foreground)',
            gutterBorder: 'var(--cm-color-gutter-border)',
            lineHighlight: 'var(--cm-color-line-highlight)',
            ...propsThemeSettings
        },
        styles: [
            {tag: t.atom, color: 'var(--cm-t-color-atom)'},
            {tag: t.number, color: 'var(--cm-t-color-number)'},
            {tag: t.comment, color: 'var(--cm-t-color-comment)'},
            {tag: t.string, color: 'var(--cm-t-color-string)'},
            {tag: t.special(t.brace), color: 'var(--cm-t-color-special-brace)'},
            {tag: t.variableName, color: 'var(--cm-t-color-variable-name)'},
            {tag: t.operator, color: 'var(--cm-t-color-operator)'},
            {tag: t.meta, color: 'var(--cm-t-color-meta)'},
            {tag: t.className, color: 'var(--cm-t-color-class-name)'},
            {tag: t.propertyName, color: 'var(--cm-t-color-property-name)'},
            {tag: t.keyword, color: 'var(--cm-t-color-keyword)'},
            {tag: t.tagName, color: 'var(--cm-t-color-tag-name)'},
            {tag: t.typeName, color: 'var(--cm-t-color-type-name)'},
            {tag: t.bool, color: 'var(--cm-t-color-bool)'},
            {tag: t.null, color: 'var(--cm-t-color-null)'},
            {tag: t.definition(t.typeName), color: 'var(--cm-t-color-definition-type-name)'},
            {tag: t.angleBracket, color: 'var(--cm-t-color-angle-bracket)'},
            {tag: t.attributeName, color: 'var(--cm-t-color-attribute-name)'},
            ...propsThemeStyles
        ],
    });

    const basicSetup = {...setup};

    const languageAliases = {'www-form': 'http'};

    const extensions = useMemo(
        () => {
            const extensions: CodeExtensions = [loadLanguage(languageAliases[language] || language)];
            Object.keys(basicSetup).forEach(ext => {
                const config = basicSetup[ext];
                if (config) {
                    if (EditorView[ext]) {
                        extensions.push(EditorView[ext]);
                    }
                    if (typeof config === 'object') {
                        delete basicSetup[ext];
                        const extensionFunction = extensionFunctions[ext];
                        if (extensionFunction) {
                            extensions.push(extensionFunction(config));
                        }
                    }
                }
            });

            extensions.push(EditorView.theme({
                '.cm-tooltip': {
                    color: 'var(--cm-color-tooltip)',
                    backgroundColor: 'var(--cm-color-tooltip-background)',
                    border: 'var(--cm-width-tooltip-border) solid var(--cm-color-tooltip-border)',
                },
                '.cm-tooltip .cm-tooltip-arrow': {
                    width: 'var(--cm-width-tooltip-arrow)',
                    height: 'var(--cm-height-tooltip-arrow)',
                    top: 'calc(var(--cm-height-tooltip-arrow) * -1)',
                    left: 'calc(var(--cm-width-tooltip-arrow) / 2)',
                },
                '.cm-tooltip-above .cm-tooltip-arrow': {
                    top: 'auto',
                    bottom: 'calc(var(--cm-height-tooltip-arrow) * -1)',
                },
                '.cm-tooltip .cm-tooltip-arrow:before, .cm-tooltip .cm-tooltip-arrow:after': {
                    borderLeftWidth: 'calc(var(--cm-width-tooltip-arrow) / 2)',
                    borderRightWidth: 'calc(var(--cm-width-tooltip-arrow) / 2)',
                    borderBottomWidth: 'var(--cm-height-tooltip-arrow)',
                    borderTopWidth: 'var(--cm-height-tooltip-arrow)',
                },
                '.cm-tooltip .cm-tooltip-arrow:before': {
                    borderTopColor: 'var(--cm-color-tooltip-border)',
                    borderBottomColor: 'var(--cm-color-tooltip-border)',
                },
                '.cm-tooltip .cm-tooltip-arrow:after': {
                    borderTopColor: 'var(--cm-color-tooltip-background)',
                    borderBottomColor: 'var(--cm-color-tooltip-background)',
                    top: 'var(--cm-width-tooltip-border)',
                },
                '.cm-tooltip-above .cm-tooltip-arrow:after': {
                    top: 'auto',
                    bottom: 'var(--cm-width-tooltip-border)',
                },
            }));

            if (propsExtensions) {
                extensions.push(...propsExtensions);
            }

            return extensions;
        },
        [language, basicSetup]
    );

    if (typeof children !== 'undefined') {
        value = '' + children;
    }

    return <div className={classNames(classPrefix('code'), propsClassName, styles('code', `theme-${themeName}`))}>
        <CodeMirror
            width="100%"
            height="100%"
            value={value}
            editable={editable}
            readOnly={readOnly}
            basicSetup={basicSetup}
            extensions={extensions}
            theme={theme}
            data-name={propsName}
            onChange={propsOnChange}
            onFocus={propsOnFocus}
            onBlur={propsOnBlur}
            onCreateEditor={propsOnCreateEditor}
            onUpdate={propsOnUpdate}
        />
    </div>;
}

export default Code;