import classNames from 'classnames';
import Icon from '~/components/common/Icon';

import classes from './Footer.module.css';
import {classPrefix, createStylesSelector} from '~/lib';
import {FooterProps} from '~/@types/components/templates/FooterProps';

function Footer({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: FooterProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <footer className={classNames(classPrefix('footer'), propsClassName, styles('footer'))}>
        <div className={styles('content')}>
            footer
            <Icon ico="Star" />
        </div>
    </footer>;
}

export default Footer;