import {configureStore} from '@reduxjs/toolkit';
import {userAPI} from '~/api/userAPI';
import {navigationAPI} from '~/api/navigationAPI';
import {mediaAPI} from '~/api/mediaAPI';
// import {setupListeners} from '@reduxjs/toolkit/query';
import rootReducer from '~/reducers';
import {useDispatch} from 'react-redux';
import {docsAPI} from '~/api/docsAPI';

// import offline from '~/enhancers/offline';

const apiMiddlewares = [
    userAPI.middleware,
    navigationAPI.middleware,
    mediaAPI.middleware,
    docsAPI.middleware,
];

const store = configureStore(
    {
        reducer: rootReducer,
        // enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(offline),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiMiddlewares),
    }
);

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export type RootState = ReturnType<typeof rootReducer>
// setupListeners(store.dispatch);

export default store;