import classNames from 'classnames';

import Icon from '~/components/common/Icon';
import {classPrefix, createStylesSelector} from '~/lib';

import classes from './Span.module.css';
import {SpanComponent, SpanProps} from '~/@types/components/common/SpanProps';
import {ReactElement} from 'react';
import {ClassesName} from '~/@types';
import {IconFilled, IconProps} from '~/@types/components/common/IconProps';

function Span<T extends SpanComponent>(props: SpanProps<T>): ReactElement {
    const {
        ico = undefined,
        Component = 'span',
        icoPosition = 'after',
        deleteProps = [],
        ...restProps
    } = props;
    let {
        children,
        title
    } = props;
    const styles = createStylesSelector([props.classes, props.styles, classes]);
    let icon: ReactElement | null = null;
    if (ico) {
        const icoProps: IconProps = typeof ico === 'object' ? {...ico} : {ico: ico as IconFilled, mode: 'filled'};
        icon = <Icon title="" classes={classes} styles={styles} {...icoProps}/>;
    }
    if (icoPosition === 'only') {
        if (children) {
            title = '' + children;
        }
        children = icon;
    } else {
        children = <>
            {icoPosition === 'before' ? icon : null}
            {children}
            {icoPosition === 'after' ? icon : null}
        </>;
    }

    let componentClasses: ClassesName[] = [
        classPrefix('span'),
    ];
    if (props.className && typeof props.className !== 'function') {
        componentClasses.push(props.className);
    }
    if (icon) {
        componentClasses = componentClasses.concat(styles(
            'with-icon',
            `ico-position-${icoPosition}`
        ));
    }

    // noinspection SuspiciousTypeOfGuard
    if (typeof Component === 'string') {
        delete restProps.styles;
    }

    restProps.className = typeof props.className === 'function'
        ? (...args: unknown[]): string | undefined => classNames(componentClasses, props.className(...args))
        : classNames(componentClasses);

    deleteProps.forEach(prop => {
        delete restProps[prop];
    });

    return <Component
        {...restProps}
        title={title}
    >
        {children}
    </Component>;
}

export default Span;