import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import classNames from 'classnames';
import md5 from 'md5';
import {DependencyList, EffectCallback, useCallback, useContext, useEffect, useRef} from 'react';
import url from 'url';
import qs from 'qs';
import {default as lodashToPath} from 'lodash/toPath';
import {default as lodashGet} from 'lodash/get';
import {default as lodashSet} from 'lodash/set';
import {default as lodashHas} from 'lodash/has';
import bytes, {Unit as BytesUnit} from 'bytes';
import xml2js from 'xml2js';
import {FieldContext} from '~/context/FieldContext';
import {FormContext} from '~/context/FormContext';
import {abbrStr as libAbbrStr} from '../lib';
import {
    CodeLanguages,
    CodeParsedReturn,
    EventHandler,
    EventObject,
    FormatCodeCode,
    FormatNumberOptions,
    FormFieldContextProps,
    HMSParts,
    NonUndefined,
    ProbeReportDiff,
    ProbeReportDiffPath,
    PropertyPath,
    StyleSelector,
    StyleSelectorCreator,
    StyleSelectorStyles,
    URLScheme
} from '~/@types';
import {RootState} from '~/store';
import {FieldContextProps, FormContextProps} from '~/@types/context';
import {RouteObject} from 'react-router-dom';
import {RouterProps} from '~/@types/components/features/RouterProps';

export const log = (...args: unknown[]) => {
    return console.log(...args); //eslint-disable-line no-console
};

// noinspection JSUnusedGlobalSymbols
export const warn = (...args: unknown[]) => {
    return console.warn(...args); //eslint-disable-line no-console
};

// noinspection JSUnusedGlobalSymbols
export const errorLog = (...args: unknown[]) => {
    return console.error(...args); //eslint-disable-line no-console
};

export const classPrefix = (className: string) => `${VITE__PREFIX__}${className}`;

class ClassNameArray extends Array {
    toString(): string {
        return classNames(this);
    }
}

export const stylesSelector: StyleSelectorCreator = (name: (string|boolean)|(string|boolean)[], styles: StyleSelectorStyles, warnMissing: boolean): ClassNameArray|undefined => {
    let ret: string | string[] | undefined = undefined;
    if (name instanceof Array && name.length === 1) {
        name = name[0];
    }
    if (name instanceof Array && name.length > 1) {
        ret = (new ClassNameArray()).concat(...(name.map(n => stylesSelector(n, styles, warnMissing)).filter(c => !!c)));
    } else if (typeof name === 'string') {
        const stylesArray = styles instanceof Array ? styles : [styles];
        ret = stylesArray.reduce(
            (ret, styles) => {
                if (styles) {
                    let foundStyle: string | string[] | undefined = undefined;
                    if (typeof styles === 'object') {
                        foundStyle = styles[classPrefix(<string>name)] || undefined;
                    } else if (typeof styles === 'function') {
                        foundStyle = styles(<string>name) || undefined;
                    }
                    if (foundStyle && foundStyle.length) {
                        ret = ret.concat(foundStyle);
                    }
                }

                return ret;
            },
            (new ClassNameArray())
        );
        if (warnMissing && !ret.length) {
            log(`Missing class name '${name}'`);
        }
    }

    return ret && ret.length ? ret : undefined;
};

export const createStylesSelector = (styles: StyleSelectorStyles, warnMissing: boolean = false): StyleSelector =>
    (...name: (string|boolean)[]) =>
        stylesSelector(name, styles, warnMissing);

// noinspection JSUnusedGlobalSymbols
export const abbrStr = libAbbrStr;

export const randRange = (min: number = 0, max: number = 9): number => {
    if (max < min) {
        max = min;
    }
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const existTokens: string[] = [];
export const uniqueToken = (len: number = 5, recDepth: number = 20, prefix: string = ''): string => {
    if (recDepth > 20) {
        recDepth = 20;
    }
    const hash = md5(`uni_${new Date().getTime()}_${Math.random()}`);
    if (len > hash.length) {
        len = hash.length;
    }
    const hashStart = randRange(0, hash.length - len);
    let token = '';
    if (prefix !== '') {
        token += prefix + '';
    }
    token += hash.slice(hashStart, hashStart + len);
    if (existTokens.indexOf(token) >= 0 && recDepth > 0) {
        token = uniqueToken(len, --recDepth, prefix);
    }
    return token;
};

// noinspection JSUnusedGlobalSymbols
export const stringToPath = (path: string): PropertyPath => lodashToPath(path);

// noinspection JSUnusedGlobalSymbols
export const hasIn = (object: object, path: PropertyPath) => lodashHas(object, path);

// noinspection JSUnusedGlobalSymbols
export const getIn = (object: object, path: PropertyPath, defaultValue: unknown) => lodashGet(object, path, defaultValue);

// noinspection JSUnusedGlobalSymbols
export const setIn = (object: object, path: PropertyPath, value: unknown) => lodashSet(object, path, value);

export const capitalize = (string: string, eachWord: boolean = true): string => {
    const replRegExp = new RegExp('\\b\\w', eachWord ? 'g' : '');
    return string ? string.replace(replRegExp, l => l.toUpperCase()) : string;
};

export const hmsPartsNumbers = (seconds: number, round: boolean = false): HMSParts => {
    if (isNaN(seconds)) {
        seconds = 0;
    }
    const roundSeconds = Math.round(seconds);
    const ms = round ? 0 : Math.round((seconds - roundSeconds) * 1000);
    seconds = roundSeconds;
    const hour= Math.floor(seconds / 3600);
    const min = Math.floor((seconds - (hour * 3600)) / 60);
    const sec = seconds - ((60 * hour) + min) * 60;

    return [
        hour,
        min,
        sec,
        ms
    ];
};

export const hmsParts = (seconds: number, round: boolean = false, unitSeparator: string = ''): string[] => {
    const parts: string[] = [];

    const [hour, min, sec, ms] = hmsPartsNumbers(seconds, round);

    if (hour > 0) {
        parts.push(`${hour}${unitSeparator}h`);
    }
    if (min > 0) {
        parts.push(`${min}${unitSeparator}m`);
    }
    parts.push(`${sec > 0 ? sec : 0}${unitSeparator}s`);
    if (ms > 0) {
        parts.push(`${ms}${unitSeparator}ms`);
    }

    return parts;
};

// noinspection JSUnusedGlobalSymbols
export const hms = (seconds: number, round: boolean = false, unitSeparator: string = ''): string => hmsParts(seconds, round, unitSeparator).join(' ');

// noinspection JSUnusedGlobalSymbols
export const queryStringify = (params: unknown, opts: object = {}) => qs.stringify(params, {encodeValuesOnly: true, ...opts}).replace(/=($|&)/gi, '$1');

// noinspection JSUnusedGlobalSymbols
export const parseURLString = (urlToParse: string): URLScheme | false => {
    let urlScheme: URLScheme | false;
    const urlAuthRegExp = /\/\/([^:/]*)(:[^@/]*)?@/;
    if (urlToParse) {
        if (urlToParse.indexOf('://') < 0) {
            urlToParse = '//' + urlToParse;
        } else {
            urlToParse = urlToParse.replace(/(\w+:\/\/)*(\w+:\/\/)/ig, '$2');
        }
        if (urlAuthRegExp.test(urlToParse)) {
            urlToParse = urlToParse.replace(
                urlAuthRegExp,
                (_match: string, login: string, pass: string) => {
                    let parsedLogin = login || '';
                    let parsedPass = pass ? pass.substring(1) : '';
                    try {
                        parsedLogin = decodeURIComponent(parsedLogin);
                    } catch (e) {
                        //do nothing
                    }
                    try {
                        parsedPass = decodeURIComponent(parsedPass);
                    } catch (e) {
                        //do nothing
                    }
                    parsedLogin = encodeURIComponent(parsedLogin);
                    parsedPass = encodeURIComponent(parsedPass);

                    return  `//${parsedLogin}${pass ? ':' + parsedPass : ''}@`;
                }
            );
        }
    }
    try {
        urlScheme = url.parse(
            urlToParse,
            false,
            true
        );
    } catch (e) {
        urlScheme = false;
    }
    if (urlScheme) {
        urlScheme.query = urlScheme.search ? qs.parse(urlScheme.search.replace(/^\?/, '')) : {};

        urlScheme.hostname = urlScheme.hostname || '';
        urlScheme.pathname = urlScheme.pathname || '';
        try {
            urlScheme.pathname = decodeURIComponent(urlScheme.pathname);
        } catch (e) {
        }
    }

    return urlScheme;
};

// noinspection JSUnusedGlobalSymbols
export const formatNumber = (number: (number | string), options: FormatNumberOptions = {}): string => {
    const {digits, digitSeparator, thousandSeparator} = {
        digits: 2,
        digitSeparator: '.',
        thousandSeparator: ',',
        ...options
    };

    if (typeof number === 'string') {
        number = parseFloat(number);
    }
    if (isNaN(number)) {
        number = 0;
    }

    const optionalDigits = digits < 0;
    const digitsNum = Math.abs(digits);
    const digitsNumber = Math.pow(10, digitsNum);

    const fixed = (Math.round(number * digitsNumber) / digitsNumber).toFixed(digitsNum);
    const parts = fixed.split('.');

    if (optionalDigits && parts[1] && parseInt(parts[1]) === 0) {
        parts.pop();
    }
    return parts.map((part, k) => thousandSeparator && k === 0
        ? part.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
        : part)
        .join(digitSeparator);
};

// noinspection JSUnusedGlobalSymbols
export const formatBytes = (bytesVal: number | string, unit: string | null = null, unitSeparator: string = ''): string => {
    if (typeof bytesVal === 'string') {
        bytesVal = parseFloat(bytesVal);
    }
    if (isNaN(bytesVal)) {
        bytesVal = 0;
    }

    const minUnitsMap = {
        KB: 1 << 10,
        MB: 1 << 20,
        GB: 1 << 30,
        TB: Math.pow(2, 40),
        PB: Math.pow(2, 50),
    };
    let minUnit: string = 'GB';
    let minUnitUpper: number = -1;
    if (unit) {
        minUnit = unit.substring(1);
        if (unit.length > 2) {
            if (unit[0] === '>') {
                minUnitUpper = 0;
            }
            unit = null;
        }
    }
    if (!unit) {
        const minVal = minUnitsMap[minUnit];
        if (minUnitUpper < 0) {
            minUnitUpper = minVal / 100;
        }
        unit = bytesVal < minVal && bytesVal >= minUnitUpper ? minUnit : '';
    }

    const retVal = bytes(
        bytesVal,
        {
            unit: unit as BytesUnit,
            unitSeparator,
        }
    );

    return retVal ? retVal : `0${unitSeparator}${minUnit}`;
};

export const isNumber = (string: unknown): boolean => {
    let ret: boolean = typeof string === 'number';
    if (!ret && typeof string === 'string') {
        ret = !isNaN(parseFloat(string));
    }
    return ret;
};

export function prepareFetchBaseQuery(baseUrl = VITE__API_ENDPOINT, headers: [string, string][] = []) {
    return fetchBaseQuery(
        {
            baseUrl,
            prepareHeaders: (fetchHeaders, {getState}) => {
                const {
                    app: {
                        'csrf-token': csrfToken,
                        'device-fingerprint': deviceFingerprint,
                    },
                    account: {'jwt-token': jwtToken}
                } = getState() as RootState;

                const headersList = [...headers];

                if (csrfToken) {
                    headersList.unshift(['X-CSRF-Token', '' + csrfToken]);
                }

                if (jwtToken) {
                    headersList.unshift(['authorization', `Bearer ${jwtToken}`]);
                }

                headersList.unshift(['X-Requested-With', 'XMLHttpRequest']);
                warn(`Fingerpring not passed ${deviceFingerprint}`);
                //headersList.unshift(['X-Device-Fingerprint', '' + deviceFingerprint]);

                headersList.forEach(header => fetchHeaders.set(...header));

                return fetchHeaders;
            }
        }
    );
}

export function useOnMountUnsafe(effect: EffectCallback, deps: DependencyList = [], refVal:boolean | string | number = true) {
    const initialized = useRef<boolean | string | number>(false);

    useEffect(() => {
        if (!initialized.current || initialized.current !== refVal) {
            initialized.current = refVal;
            effect();
        }
    }, deps);
}

export const sanitizePath = (pathString: string): string => pathString.replace(/(([^:]|^)\/)\/+/g, '$1').replace(/\/$/g, '') || '/';

export function formatCode(
    code: FormatCodeCode,
    language: CodeLanguages,
    indent: string = '    ',
    raw: boolean = false
): string {
    let ret: string = '';
    if (typeof code === 'object') {
        switch (language) {
            case 'xml': {
                const xmlBuilder = new xml2js.Builder({
                    renderOpts: {
                        pretty: !raw && !!indent,
                        indent
                    },
                    xmldec: {version: '1.0'},
                    headless: false
                });
                ret = xmlBuilder.buildObject(code);
                break;
            }
            case 'www-form': {
                const data = queryStringify(code);
                ret = raw ? data : `Content-Type: application/x-www-form-urlencoded
Content-Length: ${data.length}

${data}`;
            }
                break;
            default:
            case 'json':
            case 'javascript':
                ret = JSON.stringify(code, null, raw ? '' : indent);
                break;
        }
    } else {
        ret = '' + code;
    }

    return ret;
}

export function parseCode(codeString: string, language: CodeLanguages | 'auto' = 'auto'): {code: CodeParsedReturn, language: CodeLanguages, error: unknown} {
    let code: CodeParsedReturn = null, error: unknown = null;
    try {
        switch (language) {
            case 'xml': {
                xml2js.parseString(
                    codeString,
                    {
                        trim: true,
                        normalizeTags: true,
                        explicitArray: false,
                        ignoreAttrs: true
                    },
                    (err, result) => {
                        if (!err) {
                            code = result;
                        }
                    }
                );
                break;
            }
            default:
            case 'json':
            case 'javascript':
                code = JSON.parse(codeString.replace(/,(\s*[\]}])/gi, '$1'));
                break;
            case 'www-form': {
                const [, body] = codeString.split(/[\n\r]{2,}/); /* @todo: parse headers as well */
                if (body) {
                    code = qs.parse(body);
                }
                break;
            }
            case 'auto':
                ['json', 'xml', 'www-form'].some(mode => {
                    const parsed = parseCode(codeString, mode as CodeLanguages);
                    if (parsed.code) {
                        code = parsed.code;
                        language = parsed.language;
                        error = null;
                        return true;
                    } else {
                        error = parsed.error;
                    }
                });
                break;
        }
    } catch (e) {
        error = e;
    }

    return {
        code,
        language: language as CodeLanguages,
        error
    };
}

export function getFormFieldContextProps({
    name,
    inheritName = false,
    onChange = undefined,
    onFocus = undefined,
    onBlur = undefined,
    onSubmit = undefined
}: FormFieldContextProps): FormFieldContextProps {
    const {
        name: formName,
        onSubmit: formOnSubmit,
        onChange: formOnChange,
        onFocus: formOnFocus,
        onBlur: formOnBlur
    } = useContext(FormContext) as FormContextProps;
    const {
        name: fieldName,
        onChange: fieldOnChange,
        onFocus: fieldOnFocus,
        onBlur: fieldOnBlur
    } = useContext(FieldContext) as FieldContextProps;

    if (inheritName) {
        name = [
            formName,
            fieldName,
            name
        ].filter(p => !!p).join('.');
    }

    const eventHandlerChains = {
        onChange: [
            formOnChange,
            fieldOnChange,
            onChange
        ],
        onFocus: [
            formOnFocus,
            fieldOnFocus,
            onFocus
        ],
        onBlur: [
            formOnBlur,
            fieldOnBlur,
            onBlur
        ],
        onSubmit: [
            formOnSubmit,
            onSubmit
        ],
    };

    const eventHandlerProps = Object.keys(eventHandlerChains).reduce(
        (handlers, type) => {
            handlers[type] = createEventHandler(eventHandlerChains[type]);
            if (!handlers[type]) {
                delete handlers[type];
            }
            return handlers;
        },
        {}
    );

    return {
        name,
        ...eventHandlerProps
    } as FormFieldContextProps;
}

export function createEventHandler(chain: EventHandler[], wrapEvent: ((e: EventObject) => EventObject) | null = null): EventHandler {
    let handler: EventHandler;
    const handlersChain = chain.filter(h => typeof h === 'function');
    if (handlersChain.length > 0) {
        handler = !wrapEvent && handlersChain.length === 1
            ? handlersChain[0]
            : useCallback((e: EventObject, ...args: unknown[]) => {
                if (wrapEvent) {
                    e = wrapEvent(e);
                }
                return handlersChain.reduce(
                    (result: unknown, eventHandler: EventHandler): unknown => typeof eventHandler === 'function' ? eventHandler(e, ...args, result) : undefined,
                    undefined
                );
            }, []);
    }

    return handler;
}

export function wrapTargetEvent(e: EventObject, type: string | null = null, target: object = {}): EventObject {
    if (typeof e !== 'object' || !e.target) {
        target = {
            ...target,
            value: e
        };
        e = new Event(e.type || type || 'custom') as EventObject;
        Object.defineProperty(e, 'target', {writable: false, value: target});
    }

    return e;
}

export function isPromise(obj: {then?: () => unknown}) {
    return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}

export function parseProbeReportDiff(diff: object, path: ProbeReportDiffPath = []): ProbeReportDiff[] {
    let parsed: ProbeReportDiff[] = [];
    Object.keys(diff).forEach(k => {
        const diffPart = diff[k];
        if (diffPart && typeof diffPart === 'object') {
            const pathKey = (diff instanceof Array) ? parseInt(k) : k;
            const subPath: ProbeReportDiffPath = path.length > 0 && path[path.length - 1] === pathKey
                ? [...path]
                : [...path, pathKey];
            if (typeof diffPart.chosen !== 'undefined') {
                const diffRow: ProbeReportDiff = {
                    path: subPath,
                    diff: diffPart
                };
                parsed.push(diffRow);
            } else {
                parsed = parsed.concat(parseProbeReportDiff(
                    diffPart,
                    subPath
                ));
            }
        }
    });

    return parsed;
}

export function parseProbeReportCode(code: object | [], path: ProbeReportDiffPath = []): ProbeReportDiff[] {
    let diff: ProbeReportDiff[] = [];
    Object.keys(code).forEach(k => {
        if (k === 'diff') {
            diff = diff.concat(parseProbeReportDiff(code[k], path));
        } else if (code[k] && typeof code[k] === 'object') {
            const pathKey = (code instanceof Array) ? parseInt(k) : k;
            const parsed = parseProbeReportCode(code[k], [...path, pathKey]);
            diff = diff.concat(parsed);
        }
    });

    return diff;
}

export function parseDateString(dateString: string | number | undefined): Date | undefined {
    let dateObject: Date | undefined = undefined;
    try {
        dateObject = typeof dateString === 'undefined'
            ? new Date()
            : new Date(dateString);

        if (!isDateValid(dateObject)) {
            dateObject = undefined;
        }
    } catch (e) {
        warn(e);
    }

    return dateObject;
}

export function isDateValid(date: Date) {
    return !isNaN(date.valueOf());
}

export function prepareRoutes(
    pages: RouterProps['pages'],
    renderFunction: NonUndefined<RouterProps['renderFunction']>,
    prefixSlug: RouterProps['prefixSlug'] = '',
    prefixPath: RouterProps['prefixPath'] = ''
): RouteObject[] {
    return Object.keys(pages).map(slug => {
        const page = pages[slug];
        let {element} = page;
        let path: string = '';

        if (!element) {
            const pathParts: string[] = [
                prefixPath,
                page.path || slug,
            ];
            if (page.params) {
                const pageParams = page.params;
                const paramsParts: string[] = Object.keys(pageParams).map(
                    (param: string) => `:${param}${pageParams[param].optional ? '?' : ''}`
                );
                pathParts.push(...paramsParts);
            }
            if (page.splat) {
                pathParts.push('*');
            }

            path = sanitizePath(pathParts.join('/'));

            element = renderFunction(prefixSlug ? `${prefixSlug}-${slug}` : slug, page);
        }

        const route: RouteObject = {
            id: slug,
            path,
            caseSensitive: false,
        };
        if (page.children) {
            route.children = prepareRoutes(
                {
                    '/': {index: true, element},
                    ...page.children
                },
                renderFunction,
                slug,
                path
            );
        } else {
            route.element = element;
        }

        const isIndex = typeof page.index !== 'undefined' ? page.index : path === '/';
        if (!isIndex) {
            route.index = false;
        }

        return route;
    });
}
