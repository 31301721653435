import ReactDOM from 'react-dom/client';

import App from '~/components/features/App';
import {classPrefix, errorLog} from '~/lib';

const rootID = classPrefix('root');
const rootElement = document.getElementById(rootID);
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(<App />);
} else {
    errorLog(`Root element with id #${rootID} is missing`);
}
