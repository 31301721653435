import classNames from 'classnames';
import Menu from '~/components/common/Menu';
import SVG from '~/components/common/SVG';

import classes from './Header.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {HeaderProps} from '~/@types/components/templates/HeaderProps';

function Header({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: HeaderProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <header className={classNames(classPrefix('header'), propsClassName, styles('header'))}>
        <SVG styles={styles} className={styles('logo')} path="assets/logo.svg" link linkProps={{slug: 'index'}}/>
        <nav className={styles('menu')}><Menu styles={styles} name="top" mode="horizontal" subMenuClassName={styles('header-sub-menu')}/></nav>
    </header>;
}

export default Header;