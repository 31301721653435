import Header from '~/components/templates/Header';
import Main from '~/components/templates/Main';
import Footer from '~/components/templates/Footer';

import classes from './Wrapper.module.css';
import {classPrefix, createStylesSelector} from '~/lib';
import {WrapperProps} from '~/@types/components/templates/WrapperProps';
import classNames from 'classnames';

function Wrapper({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: WrapperProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <div className={classNames(classPrefix('wrapper'), propsClassName, styles('wrapper'))}>
        <Header  {...props} className={styles('header')} styles={styles} classes={classes} />
        <Main {...props} className={styles('main')} styles={styles} classes={classes} />
        <Footer {...props} className={styles('footer')} styles={styles} classes={classes} />
    </div>;
}

export default Wrapper;