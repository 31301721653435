import classNames from 'classnames';
import Link from '~/components/common/Link';
import APISample from '~/components/templates/APISample';

import classes from './PageIndex.module.css';
import {classPrefix, createStylesSelector} from '~/lib';
import {PageIndexProps} from '~/@types/components/pages/PageIndexProps';

function PageIndex(props: PageIndexProps) {
    const styles = createStylesSelector([props.classes, props.styles, classes]);

    return <section className={classNames(classPrefix('page-index'), props.className, '' + styles('page-index'))}>
        <h1>Instant and accurate<br />video inspection</h1>
        <h2>Integrate an ultra fast request and response media inspection micro service<br />with just a few lines of code and stop managing media analysis software.</h2>
        <div className={'' + styles('signup-links')}>
            <Link styles={styles} slug="signup" asButton bold>Start free trial</Link>
            <Link styles={styles} slug="docs" asButton>View Documentation</Link>
        </div>
        <APISample styles={styles} className={styles('api-request')}/>
    </section>;
}

export default PageIndex;