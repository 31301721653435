import {useLocation, useMatches} from 'react-router-dom';

import {useOnMountUnsafe} from '~/lib';
import {locationChange} from '~/reducers/app';

import Wrapper from '~/components/templates/Wrapper';
import {RouteProps} from '~/@types/components/features/RouteProps';
import {useAppDispatch} from '~/store';

export default function Route({
    page,
    slug,
    Component = Wrapper,
    ...props
}: RouteProps){
    const location = useLocation();
    const matches = useMatches();
    const dispatch = useAppDispatch();

    useOnMountUnsafe(
        () => {
            dispatch(locationChange({
                location,
                matches,
                slug,
                page
            }));
        },
        [
            location,
            page,
            slug,
        ],
        location.key
    );
    return <Component {...props}/>;
}