import classNames from 'classnames';

import classes from './PageDocsGuides.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {PageDefaultProps} from '~/@types/components/pages/PageDefaultProps';
import {useGetGuideQuery, useGetGuidesQuery} from '~/api/docsAPI';
import Loader from '~/components/common/Loader';
import {DocsAPIGuidesResponse} from '~/@types/api/docsAPI';
import {ReactNode} from 'react';
import Router from '~/components/features/Router';
import {useSelector} from 'react-redux';
import {getPageSlug} from '~/reducers/page';
import Menu from '~/components/common/Menu';
import Block from '~/components/common/Block';
import Markdown from '~/components/common/Markdown';
import DateTime from '~/components/common/Date';

export default function PageDocsGuides({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const rootSlug = useSelector(getPageSlug);
    const {
        data: guidesData,
        isLoading,
        isFetching
    } = useGetGuidesQuery(rootSlug || '');

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    function renderGuides(guidesData: DocsAPIGuidesResponse | undefined): ReactNode {
        let ret: ReactNode;
        if (guidesData && guidesData.guides) {
            ret = <Router
                pages={guidesData.guides}
                renderFunction={renderGuideContents}
                prefixSlug={'docs-guides'}
            />;
        }

        return ret;
    }

    function renderGuideContents(slug: string): ReactNode {
        const guideSlug = slug.replace(new RegExp(`^${rootSlug}-`), '');
        const {
            data: guideData,
            isLoading,
            isFetching
        } = useGetGuideQuery(guideSlug);
        return <>
            <nav className={styles('sidebar')}>
                <Menu
                    styles={styles}
                    name="guides"
                    mode="inline"
                    className={styles('guides-menu')}
                    subMenuClassName={styles('guides-sidebar-sub-menu')}
                    defaultOpenKeys={[slug]}
                    defaultSelectedKeys={[slug]}
                    inheritKeyPrefix={false}
                    slugAsKey={true}
                />
            </nav>
            <div className={styles('guide-data')}>
                {!isLoading && !isFetching
                    ? guideData ? <Block
                        styles={styles}
                        header={guideData.guide.title}
                        meta={[
                            {field: 'created', title: 'Created', content: <DateTime>{guideData.guide.created}</DateTime>},
                            {field: 'updated', title: 'Updated', content: <DateTime>{guideData.guide.updated}</DateTime>},
                        ]}
                    >
                        <Markdown>{guideData.guide.description}</Markdown>
                    </Block> : 'no data'
                    : <Loader size="large"/>
                }
            </div>
        </>;
    }

    return <section className={classNames(classPrefix('page-docs-guides'), propsClassName, styles('page-docs-guides'))}>
        <h1>Guidelines</h1>
        {!isLoading && !isFetching
            ? <div className={styles('guides-container')}>{renderGuides(guidesData)}</div>
            : <Loader size="large"/>
        }
    </section>;
}