import {createApi} from '@reduxjs/toolkit/query/react';
import {prepareFetchBaseQuery} from '~/lib';
import {NavigationAPIGetMenusResponse} from '~/@types/api/naviagationAPI';

export const navigationAPI = createApi({
    reducerPath: 'navigationAPI',
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (builder) => ({
        getMenus: builder.query<NavigationAPIGetMenusResponse, void>({
            query: () => 'navigation/menus',
            // keepUnusedDataFor: 0.0001,
            // forceRefetch: true,
            // refetchOnMountOrArgChange: true
        }),
    }),
});
