import classNames from 'classnames';

import ReactMarkdown from 'react-markdown';

import classes from './Markdown.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {MarkdownProps} from '~/@types/components/common/MarkdownProps';

export default function Markdown({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    ...props
}: MarkdownProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    return <ReactMarkdown
        {...props}
        className={classNames(classPrefix('markdown'), propsClassName, styles('markdown'))}
    />;
}