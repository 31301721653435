import classNames from 'classnames';

import classes from './FieldError.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {FieldErrorProps} from '~/@types/components/common/FieldErrorProps';

export default function FieldError({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    children
}: FieldErrorProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <div className={classNames(classPrefix('field-error'), propsClassName, styles('field-error'))}>{children}</div>;
}
