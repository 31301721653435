import classNames from 'classnames';

import classes from './Loader.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {LoaderProps} from '~/@types/components/common/LoaderProps';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import {ReactNode} from 'react';

export default function Loader({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    type = 'spinner',
    size = 'default',
    animate = true,
    position = 'relative'
}: LoaderProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    let loader: ReactNode = null;
    switch (type) {
        case 'spinner':
        default:
            loader = <LoadingOutlined className={styles('spinner')} spin={animate} />;
            break;
        case 'text':
            loader = <>
                loading
                <span className={styles('dot')}>.</span>
                <span className={styles('dot')}>.</span>
                <span className={styles('dot')}>.</span>
            </>;
            break;
        case 'none':
            loader = null;
            break;
    }

    const loaderClassNames: string[] = [
        'loader',
        `type-${type}`,
        `position-${position}`,
        `size-${size}`,
    ];
    if (animate) {
        loaderClassNames.push('animate');
    }

    return loader ? <span className={classNames(classPrefix('loader'), propsClassName, styles(...loaderClassNames))}>
        <span className={styles('container')}>
            {loader}
        </span>
    </span> : null;
}
