import classNames from 'classnames';
import {useSelector} from 'react-redux';

import {NavLink as RouterLink} from 'react-router-dom';
import Span from '~/components/common/Span';
import {getSlugInfo} from '~/reducers/navigation';

import classes from './Link.module.css';
import buttonClasses from './Button.module.pcss';
import {classPrefix, createStylesSelector, sanitizePath} from '~/lib';
import {LinkProps} from '~/@types/components/common/LinkProps';
import {ComponentPropsWithoutRef} from 'react';


export default function Link({
    to = '/',
    slug = '',
    native = false,
    asButton = false,
    bold = false,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: LinkProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, buttonClasses, classes]);
    const Component: 'a' | typeof RouterLink = native ? 'a' : RouterLink;
    const anchorProps: ComponentPropsWithoutRef<'a'> = {};
    const linkProps: ComponentPropsWithoutRef<typeof RouterLink> = {to};
    let linkChildren = props.children;

    if (slug) {
        const {path: slugPath, title: slugTitle} = useSelector(getSlugInfo)(slug);
        if (slugPath) {
            to = sanitizePath(`${slugPath}/${to || ''}`);
            if (!native) {
                linkProps.relative = 'path';
            }
        }
        if (slugTitle && typeof linkChildren === 'undefined') {
            linkChildren = slugTitle;
        }
    }

    if (native) {
        anchorProps.href = to;
    } else {
        linkProps.to = to;
        linkProps.caseSensitive = false;
    }

    const lProps = native ? anchorProps : linkProps;

    return <Span
        {...props}
        {...lProps}
        deleteProps={['styles']}
        Component={Component}
        className={({isActive, isPending, isTransitioning }) =>
            classNames(
                classPrefix('link'),
                propsClassName,
                styles(
                    'link',
                    asButton && 'button',
                    bold && 'bold',
                    isActive && 'active',
                    isPending && 'pending',
                    isTransitioning && 'transitioning'
                )
            )}
    >{linkChildren}</Span>;
}