import classNames from 'classnames';
import Button from '~/components/common/Button';
import CodeTabs from '~/components/common/CodeTabs';
import Field from '~/components/common/Field';
import FieldError from '~/components/common/FieldError';

import Form from '~/components/common/Form';

import {capitalize, createStylesSelector} from '~/lib';
import {APISampleFormProps} from '~/@types/components/forms/APISampleFormProps';
import {CodeProps} from '~/@types/components/common/CodeProps';
import Code from '~/components/common/Code';

function APISampleCodeRequestField({styles, name,  ...props}: CodeProps) {
    return <Field classes={{}} styles={styles} name={`request.${name}`}>{({input, meta: {error, submitError, touched}, fieldName}) => <>
        <Code
            name={fieldName}
            {...props}
        >{input.value}</Code>
        {submitError || touched && error ? <FieldError classes={{}} styles={styles} className={styles('request-error')}>{submitError || error}</FieldError> : null}
    </>
    }</Field>;
}

function APISampleForm({
    name,
    loading,
    classes: propsClasses,
    styles: propsStyles,
    initialValues,
    ...props
}: APISampleFormProps) {
    const styles = createStylesSelector([propsClasses, propsStyles]);

    const itemLabels = {'www-form': 'HTTP'};

    const items = Object.keys(initialValues.request).map(language => ({
        name: language,
        key: language,
        label: itemLabels[language] || capitalize(language),
        editable: !loading,
        disabled: loading,
        language,
        code: initialValues.request[language],
    }));

    return <Form
        {...props}
        initialValues={initialValues}
        name={name || 'api-sample-form'}
        classes={{}}
        styles={styles}
        className={classNames(props.className, styles('api-sample-form'))}
        mutators={{
            setFormat: ([activeKey], state, utils) => {
                utils.changeValue(state, 'format', () => activeKey);
            }
        }}
    >
        {({form, submitting}) => <>
            <Field name="format" type="hidden" classes={{}} styles={styles}>
                {({input, meta: {fieldName}}) => <input {...input} name={fieldName}/>}
            </Field>
            <CodeTabs
                styles={styles}
                items={items}
                className={styles('code-sample', 'request-tabs')}
                tabBarExtraContent={{left: 'Request'}}
                defaultActiveKey={'json'}
                prettyPrint
                CodeComponent={APISampleCodeRequestField}
                onChange={(activeKey: string) => form.mutators.setFormat(activeKey)}
                addCopy
            />

            <Button
                ant="default"
                className={styles('submit')}
                bold
                disabled={loading || submitting}
                type="submit"
            >
                {loading ? 'loading...' : 'Submit!'}
            </Button>
        </>}
    </Form>;
}

export default APISampleForm;