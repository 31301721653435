import {createApi} from '@reduxjs/toolkit/query/react';
import {prepareFetchBaseQuery} from '~/lib';
import {UserAPIGetCurrentUserResponse} from '~/@types/api/userAPI';

export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (builder) => ({
        auth: builder.mutation({
            query: credentials => ({
                url: '/user/auth',
                method: 'POST',
                body: credentials,
            })
        }),
        getCurrentUser: builder.query<UserAPIGetCurrentUserResponse, void>({
            query: () => 'user/current',
            // keepUnusedDataFor: 0.0001,
            // forceRefetch: true,
            // refetchOnMountOrArgChange: true
        }),
        getUserByID: builder.query({query: (id) => `user/${id || ''}`}),
    }),
});
