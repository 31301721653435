import classNames from 'classnames';

import classes from './PageDocs.module.css';
import {classPrefix, createStylesSelector} from '~/lib';
import {PageDefaultProps} from '~/@types/components/pages/PageDefaultProps';
import Link from '~/components/common/Link';

export default function PageDocs({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName
}: PageDefaultProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix('page-docs'), propsClassName, styles('page-docs'))}>
        <h1>Check out our guidelines or API reference</h1>
        <div className={'' + styles('docs-links')}>
            <Link styles={styles} slug="docs-reference" asButton bold>API Reference</Link>
            <Link styles={styles} slug="docs-guides" asButton>Guidelines</Link>
            <Link styles={styles} slug="docs-changelog" asButton>Changelog</Link>
        </div>
    </section>;
}