import classNames from 'classnames';
import {useSelector} from 'react-redux';
import Link from '~/components/common/Link';
import {getMenu} from '~/reducers/navigation';

import classes from './Menu.module.pcss';
import {classPrefix, createStylesSelector} from '~/lib';
import {MenuItem, MenuProps} from '~/@types/components/common/MenuProps';

import {Menu as AntdMenu} from 'antd';
import type {ItemType as AntdItemType} from 'antd/es/menu/interface';
import {StyleSelector} from '~/@types';
import {ReactNode} from 'react';

function prepareAntdMenuItems(
    items: MenuItem[],
    options: {
        slugPrefix?: string,
        keyPrefix?: string,
        inheritKeyPrefix?: boolean,
        slugAsKey?: boolean,
        subMenuClassName?: MenuProps['className'],
        styles: StyleSelector
    }
): AntdItemType[] {
    const {
        slugPrefix = '',
        keyPrefix = '',
        slugAsKey = false,
        inheritKeyPrefix= true,
        subMenuClassName,
        styles
    } = options;
    return items.map((item: MenuItem, k): AntdItemType => {
        let {key, slug} = item;
        if (slugPrefix && slug) {
            slug = `${slugPrefix}-${slug}`;
        }
        if (slug && slugAsKey) {
            key = slug;
        }
        if (typeof key === 'undefined') {
            key = '';
            if (slug) {
                key += slug;
            }
            if (item.to) {
                key += item.to;
            }
            if (key === '') {
                key += k;
            }
        }
        if (keyPrefix) {
            key = `${keyPrefix}-${key}`;
        }
        let menuItem: AntdItemType;

        if (item.items && item.items.length) {
            menuItem = {
                type: 'submenu',
                key,
                className: classNames(classPrefix('sub-menu'), subMenuClassName, styles('sub-menu')),
                popupClassName: classNames(classPrefix('sub-menu'), subMenuClassName, styles('sub-menu')),
                children: prepareAntdMenuItems(
                    item.items,
                    {
                        ...options,
                        keyPrefix: inheritKeyPrefix ? key : '',
                    }
                )
            };
        } else {
            menuItem = {
                type: 'item',
                key,
            };
        }
        let itemLabel: ReactNode;
        if (item.children) {
            itemLabel = item.children;
        } else {
            const ItemComponent = item.Component || Link;
            itemLabel = <ItemComponent
                {...(item.props || {})}
                styles={styles}
                to={item.to}
                slug={slug}
            >
                {item.title}
            </ItemComponent>;
        }
        menuItem.label = <div className={styles('item')}>{itemLabel}</div>;

        return menuItem;
    });
}

export default function Menu({
    name,
    items = [],
    itemsAppend = [],
    itemsPrepend = [],
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    subMenuClassName: propsSubMenuClassName,
    mode = 'inline',
    inlineIndent = 0,
    disabledOverflow = true,
    inheritKeyPrefix = true,
    slugAsKey = false,
    ...props
}: MenuProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    let slugPrefix = '';
    if (name) {
        const menu = useSelector(getMenu)(name);
        if (menu) {
            items = menu.items || [];
            slugPrefix = menu.slugPrefix || '';
        }
    }

    const allItems: MenuItem[] = ([] as MenuItem[]).concat(
        itemsPrepend,
        items,
        itemsAppend
    );

    return <AntdMenu
        {...props}
        mode={mode}
        inlineIndent={inlineIndent}
        disabledOverflow={disabledOverflow}
        items={prepareAntdMenuItems(
            allItems,
            {
                slugPrefix,
                keyPrefix: '',
                inheritKeyPrefix,
                slugAsKey,
                subMenuClassName: propsSubMenuClassName,
                styles
            }
        )}
        className={classNames(classPrefix('menu'), propsClassName, styles('menu'))}
    />;
}