import {createSlice} from '@reduxjs/toolkit';

import {APP_STATUS_INITIAL} from '~/constants/App';
import {AppInitialState} from '~/@types/reducers/app';

const initialState: AppInitialState = {
    status: null,
    location: {},
    matches: [],
    'csrf-token': null,
    'device-fingerprint': null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        init: (state, action) => ({
            ...state,
            'status': APP_STATUS_INITIAL,
            'device-fingerprint': action.payload.fingerprint
        }),
        locationChange: (state, action) => {
            state.location = action.payload.location;
            state.matches = action.payload.matches;
        }
    },
    selectors: {
        getAppStatus: state => state.status,
        getLocation: state => state.location,
        getMatches: state => state.matches
    },
});

export const {init, locationChange} = appSlice.actions;

export const {getAppStatus} = appSlice.selectors;

export default appSlice;