import {createApi} from '@reduxjs/toolkit/query/react';
import {formatCode, prepareFetchBaseQuery} from '~/lib';
import {MediaAPIPostMediaParameters, MediaAPIPostMediaResponse} from '~/@types/api/mediaAPI';
import {getAuthToken, getJWTToken} from '~/reducers/account';
import {AccountInitialState} from '~/@types/reducers/account';

export const mediaAPI = createApi({
    reducerPath: 'mediaAPI',
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (builder) => ({
        postMedia: builder.mutation<
            MediaAPIPostMediaResponse,
            MediaAPIPostMediaParameters
        >({
            queryFn: async (
                {format, body},
                baseQueryAPI,
                _extraOptions,
                baseQuery
            ) => {
                const authToken = getAuthToken(baseQueryAPI.getState() as {account: AccountInitialState});
                const jwtToken = getJWTToken(baseQueryAPI.getState() as {account: AccountInitialState});
                let content: string;
                switch (format) {
                    case 'www-form':
                        content = 'application/x-www-form-urlencoded';
                        break;
                    case 'json':
                    default:
                        content = 'application/json';
                        break;
                }
                return baseQuery({
                    url: `/media${jwtToken ? '' : `?token=${authToken}` }`,
                    method: 'POST',
                    body: formatCode(body, format, '', true),
                    headers: new Headers({'Content-Type': content}),
                }) as {
                    data?: MediaAPIPostMediaResponse,
                    error?: any,
                    meta?: any,
                };
            }
        })
    }),
});

export const {usePostMediaMutation} = mediaAPI;
