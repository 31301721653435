import {createApi} from '@reduxjs/toolkit/query/react';
import {prepareFetchBaseQuery} from '~/lib';
import {DocsAPIChangelogResponse, DocsAPIGuideResponse, DocsAPIGuidesResponse} from '~/@types/api/docsAPI';

export const docsAPI = createApi({
    reducerPath: 'docsAPI',
    baseQuery: prepareFetchBaseQuery(),
    endpoints: (builder) => ({
        getChangelog: builder.query<DocsAPIChangelogResponse, void>({
            query: () => 'docs/changelog',
            // keepUnusedDataFor: 0.0001,
            // forceRefetch: true,
            // refetchOnMountOrArgChange: true
        }),
        getGuides: builder.query<DocsAPIGuidesResponse, string>({
            queryFn: async (
                slug: string = '',
                _baseQueryAPI,
                _extraOptions,
                baseQuery
            ) => {
                return Promise.resolve(baseQuery({
                    url: 'docs/guides',
                    method: 'GET',
                }) as {
                    data?: DocsAPIGuidesResponse,
                    error?: any,
                    meta?: any,
                }).then(res => ({
                    ...res,
                    meta: {
                        ...res.meta,
                        slug
                    }
                }));
            },
            // keepUnusedDataFor: 0.0001,
            // forceRefetch: true,
            // refetchOnMountOrArgChange: true
        }),
        getGuide: builder.query<DocsAPIGuideResponse, string>({
            query: (guideSlug) => `docs/guides/${guideSlug}`,
            // keepUnusedDataFor: 0.0001,
            // forceRefetch: true,
            // refetchOnMountOrArgChange: true
        }),
    }),
});

export const {useGetChangelogQuery, useGetGuidesQuery, useGetGuideQuery} = docsAPI;