import classNames from 'classnames';
import {usePostMediaMutation} from '~/api/mediaAPI';
import CodeTabs from '~/components/common/CodeTabs';
import APISampleForm from '~/components/forms/APISampleForm';

import classes from './APISample.module.css';
import {capitalize, classPrefix, createStylesSelector, formatCode, parseCode, parseProbeReportCode} from '~/lib';
import {APISampleProps, DecorationsRange, DecorationsWidget} from '~/@types/components/templates/APISampleProps';
import {CodeTabsItem, CodeTabsItems} from '~/@types/components/common/CodeTabsProps';
import {CodeLanguages, ProbeReportDiff, ProbeReportDiffPath, StyleSelector} from '~/@types';
import {APISampleValues} from '~/@types/components/forms/APISampleFormProps';
import {syntaxTree} from '@codemirror/language';
import {SyntaxNode} from '@lezer/common';
import {
    Decoration,
    DecorationSet,
    EditorState,
    EditorView,
    Extension,
    StateField,
    ViewPlugin,
    ViewUpdate
} from '@uiw/react-codemirror';

import {hoverTooltip, Tooltip} from '@codemirror/view';
import {MediaAPIPostMediaRequest} from '~/@types/api/mediaAPI';

function getPropertyName(node: SyntaxNode, state: EditorState): string | undefined {
    let name: string | undefined;
    if (node.name === 'Property') {
        const propNameNode = node.node.getChild('PropertyName');
        if (propNameNode) {
            name = getPropertyName(propNameNode, state);
        }
    } else if (node.name === 'PropertyName') {
        name = state.doc.sliceString(node.from + 1, node.to - 1);
    }

    return name;
}

function createProbeDevDiffMarkerPlugins(diff: ProbeReportDiff[], styles: StyleSelector): Extension[] {
    function prepareDecorationSet(callback: (ranges: DecorationsRange[]) => void): DecorationSet {
        const widgets: DecorationsWidget[] = [];
        const ranges: DecorationsRange[] = [];
        callback(ranges);
        ranges.sort(({from: aFrom}, {from: bFrom}) => aFrom < bFrom ? -1 : 1);
        widgets.push(...ranges.map(({from, to, decoration}) => decoration.range(from, to)));

        return Decoration.set(widgets);
    }

    const hideStateField = StateField.define<DecorationSet>({
        create(state) {
            const decoration = Decoration.replace({block: true});
            return prepareDecorationSet(ranges => {
                syntaxTree(state).iterate({
                    enter: nodeRef => {
                        let enter: boolean | undefined = undefined;
                        if (nodeRef.name === 'Property') {
                            const propertyName = getPropertyName(nodeRef.node, state);
                            if (propertyName === 'diff') {
                                let from: number = nodeRef.node.from;
                                const to: number = nodeRef.node.to;
                                const cursor = nodeRef.node.cursor();
                                if (cursor.prevSibling()) {
                                    from = cursor.node.to;
                                }

                                ranges.push({
                                    from,
                                    to,
                                    decoration,
                                });
                                enter = false;
                            }
                        }

                        return enter;
                    }
                });
            });
        },
        update(decorators) {
            return decorators;
        },
        provide: f => EditorView.decorations.from(f)
    });

    const markerPlugin = ViewPlugin.fromClass(
        class ProbeDevDiffMarkerPlugin {
            decorations: DecorationSet;

            constructor(view: EditorView) {
                this.decorations = this.checkTree(view);
            }

            update(update: ViewUpdate) {
                if (update.docChanged || update.viewportChanged || syntaxTree(update.startState) !== syntaxTree(update.state)) {
                    this.decorations = this.checkTree(update.view);
                }
            }

            getPropertyName(node: SyntaxNode, state: EditorState): string | undefined {
                return getPropertyName(node, state);
            }

            findNodeByPath(path: ProbeReportDiffPath, state: EditorState, from: number, to: number): SyntaxNode | null {
                const unskippableNodes: string[] = [
                    'Array',
                    'Object',
                    'Property',
                    'PropertyName',
                    'JsonText',
                ];
                let found: SyntaxNode | null = null;
                let pathIndex = 0;
                let curArrayIndex: number | null = null;
                syntaxTree(state).iterate({
                    from,
                    to,
                    enter: (node: SyntaxNode) => {
                        let enter: boolean | undefined = undefined;
                        if (pathIndex < path.length && unskippableNodes.includes(node.name)) {
                            const curPath = path[pathIndex];
                            let matched: boolean = false;
                            if (typeof curPath === 'string' && node.name === 'Property') {
                                enter = false;
                                const propertyName = this.getPropertyName(node, state);
                                enter = matched = propertyName === curPath;
                            } else if (typeof curPath === 'number') {
                                if (node.name === 'Array') {
                                    enter = true;
                                    curArrayIndex = 0;
                                } else if (typeof curArrayIndex === 'number' && !!node.node.parent && node.node.parent.name === 'Array') {
                                    matched = curArrayIndex === curPath;
                                    curArrayIndex++;
                                }
                            }
                            if (matched) {
                                pathIndex++;
                                if (pathIndex >= path.length) {
                                    found = node.node;
                                }
                            }
                        } else {
                            enter = false;
                        }

                        return !found && enter;
                    },
                    leave: node => {
                        const curPath = path[pathIndex];
                        if (typeof curPath === 'number' && node.name === 'Array') {
                            curArrayIndex = null;
                        }
                    }
                });


                return pathIndex >= path.length ? found : null;
            }

            checkTree(view: EditorView) {
                return prepareDecorationSet(ranges => {
                    for (const {from, to} of view.visibleRanges) {
                        diff.forEach(
                            ({path, diff}) => {
                                const node = this.findNodeByPath(
                                    path,
                                    view.state,
                                    from,
                                    to
                                );
                                if (node) {
                                    const decoration = Decoration.mark({
                                        class: styles('probe-dev-diff-marker'),
                                        inclusive: false,
                                        diff,
                                    });
                                    const propNameNode = node.getChild('PropertyName');
                                    if (propNameNode) {
                                        ranges.push({
                                            from: propNameNode.from + 1,
                                            to: propNameNode.to - 1,
                                            decoration
                                        });
                                        const c = propNameNode.cursor();
                                        if (c.nextSibling() && c.node) {
                                            ranges.push({
                                                from: c.node.name === 'String' ? c.node.from + 1 : c.node.from,
                                                to: c.node.name === 'String' ? c.node.to - 1 : c.node.to,
                                                decoration
                                            });
                                        }
                                    } else {
                                        ranges.push({
                                            from: node.from,
                                            to: node.to,
                                            decoration
                                        });
                                    }
                                }
                            },
                            []
                        );
                    }
                });
            }
        },
        {decorations: v => v.decorations}
    );
    const hoverTooltipPlugin = hoverTooltip(
        (view, pos) => {
            const diffPlugin = view.plugin(markerPlugin);
            let tooltip: Tooltip | null = null;
            if (diffPlugin && diffPlugin.decorations) {
                diffPlugin.decorations.between(
                    pos,
                    pos,
                    (_, to, value) => {
                        if (value.spec.diff) {
                            tooltip = {
                                pos,
                                end: to,
                                above: false,
                                strictSide: true,
                                arrow: true,
                                create() {
                                    const dom = document.createElement('div');
                                    dom.className = styles('probe-dev-diff-tooltip-content');
                                    const dl = document.createElement('dl');
                                    const bounder = document.createElement('div');
                                    bounder.className = styles('bounder');
                                    Object.keys(value.spec.diff).forEach(tool => {
                                        let val = value.spec.diff[tool];
                                        const div = document.createElement('div');
                                        div.className = classNames(styles('tool', `tool-${tool}`));
                                        const dt = document.createElement('dt');
                                        const span = document.createElement('span');
                                        span.textContent = tool;
                                        const dd = document.createElement('dd');
                                        let type: string = 'unknown';
                                        let valString: string;
                                        if (typeof val !== 'undefined' && val !== null) {
                                            if (typeof val === 'string' || typeof val ==='number') {
                                                valString = '' + val;
                                                const numVal = parseFloat(valString);
                                                if (!isNaN(numVal)) {
                                                    val = numVal;
                                                }
                                                type = typeof val;
                                            } else {
                                                type = 'non-scalar';
                                                valString = JSON.stringify(val, null, ' ');
                                            }
                                        } else {
                                            valString = 'n/a';
                                        }
                                        dd.className = styles(`type-${type}`);
                                        dd.textContent = valString;

                                        dt.append(span, ': ');
                                        div.append(dt, dd);
                                        dl.append(div);
                                    });
                                    dom.append(dl, bounder);
                                    return {dom};
                                }
                            };
                        }
                    }
                );
            }

            return tooltip;
        },
        {hoverTime: 200}
    );

    return [
        hideStateField,
        markerPlugin,
        hoverTooltipPlugin,
    ];
}

function APISample({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: APISampleProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const [postMedia, postMediaProps] = usePostMediaMutation({fixedCacheKey: 'media-sample-request'});

    const {
        isLoading,
        error: mediaError,
        fulfilledTimeStamp,
        startedTimeStamp,
        isError,
        isSuccess,
        data: response
    } = postMediaProps;

    const  sendRequest = async (values: APISampleValues) => {
        const {request, format} = values;
        const parsed = parseCode(request && request[format], format);
        if (!parsed.code) {
            return {request: `Invalid request.${parsed.error ? ` Error: ${parsed.error instanceof Error ? parsed.error.message : parsed.error}` : ''}`};
        } else {
            const sourceURL: string | null = parsed.code.source
                && typeof parsed.code.source === 'object'
                && !(parsed.code.source instanceof Array)
                && '' + parsed.code.source.url
                || null;
            if (!sourceURL) {
                return {request: 'Invalid request. Error: Source URL is required'};
            } else {
                const body: MediaAPIPostMediaRequest = {
                    source: {url: sourceURL},
                    tools: parsed.code.tools as MediaAPIPostMediaRequest['tools']
                };
                await postMedia({
                    format: parsed.language,
                    body
                });
            }
        }
    };

    const responseTime = response && response.media.meta.time || startedTimeStamp && fulfilledTimeStamp && (fulfilledTimeStamp - startedTimeStamp) || 0;
    const responseTabs: CodeTabsItems = [];
    const toolsLabels = {
        probe_report: 'Probe.dev',
        mediainfo: 'Mediainfo',
        ffprobe: 'FFProbe',
        gadget: 'Gadget',
    };
    if (isError) {
        if (mediaError) {
            responseTabs.push({
                key: 'error',
                label: 'Error',
                language: 'json',
                code: mediaError
            });
        }
    } else if (response && isSuccess) {
        if (response.media.tools && typeof response.media.tools === 'object') {
            Object.keys(response.media.tools).forEach(tool => {
                const toolReport = response.media.tools[tool];
                if (toolReport && typeof toolReport === 'object') {
                    const responseTabItem: CodeTabsItem<undefined> = {
                        key: tool,
                        label: toolsLabels[tool] || capitalize(tool),
                        language: 'json',
                        code: response.media.tools[tool],
                    };
                    if (tool === 'probe_report') {
                        const diff = parseProbeReportCode(toolReport);

                        const diffMarkerPlugins = createProbeDevDiffMarkerPlugins(diff, styles);

                        responseTabItem.extensions = [...diffMarkerPlugins];

                    }
                    responseTabs.push(responseTabItem);
                }
            });
        } else {
            responseTabs.push({
                key: 'plain',
                label: 'Plain',
                language: 'json',
                code: response
            });
        }
    }

    const request = {
        source: {url: 'https://probeqa.s3.amazonaws.com/trusted_sources/video/sample-source.mp4'},
        tools: {
            probe_report: {
                enabled: true,
                diff: true
            },
            mediainfo: {enabled: true},
            ffprobe: {enabled: true},
        }
    };
    const allowedRequestFormats: CodeLanguages[] = ['json'];//, 'www-form']; todo: form data temporary unavailable
    const initialValues: APISampleValues = {
        request: {},
        format: allowedRequestFormats[0]
    };
    allowedRequestFormats.forEach(format => {
        initialValues.request[format] = formatCode(request, format, '    ');
    });

    return <div className={classNames(classPrefix('api-sample'), propsClassName, styles('api-sample'))}>
        <APISampleForm
            styles={styles}
            classes={classes}
            onSubmit={sendRequest}
            initialValues={initialValues}
            loading={isLoading}
            name="api-sample-form"
        />

        {responseTabs.length ? <CodeTabs
            styles={styles}
            items={responseTabs}
            className={styles('code-sample', 'response-tabs')}
            addCopy
            tabBarExtraContent={{
                left: 'Response',
                right: `TIME: ${responseTime}ms`,
            }}
            defaultActiveKey={responseTabs[0].key}
            prettyPrint
        /> : null}
    </div>;
}

export default APISample;
