import classNames from 'classnames';
import {Field as ReactFinalFormField} from 'react-final-form';

import FieldContext from '~/context/FieldContext';

import classes from './Field.module.pcss';
import {classPrefix, createEventHandler, createStylesSelector, getFormFieldContextProps, wrapTargetEvent} from '~/lib';
import {FieldProps} from '~/@types/components/common/FieldProps';
import {EventHandler} from '~/@types';

export default function Field({
    children,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: FieldProps) {
    const {
        name,
        onChange,
        onBlur,
        onFocus
    } = getFormFieldContextProps(props);

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    return <ReactFinalFormField
        {...props}
        name={props.name}
        onChange={onChange}
        className={classNames(classPrefix('field'), propsClassName, styles('field'))}
    >
        {({input, ...props}) => <FieldContext.Provider value={{
            name,
            onChange
        }}>
            {children({
                input: {
                    ...input,
                    name,
                    onChange: createEventHandler([input.onChange as EventHandler, onChange], e => wrapTargetEvent(e, 'change', {name})),
                    onBlur: createEventHandler([input.onBlur as EventHandler, onBlur], e => wrapTargetEvent(e, 'blur', {name})),
                    onFocus: createEventHandler([input.onFocus as EventHandler, onFocus], e => wrapTargetEvent(e, 'focus', {name})),
                },
                ...props,
                fieldName: name,
                name
            })}
        </FieldContext.Provider>}
    </ReactFinalFormField>;
}