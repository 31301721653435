import classNames from 'classnames';

import classes from './Icon.module.css';
import {capitalize, classPrefix, createStylesSelector} from '~/lib';
import {IconProps} from '~/@types/components/common/IconProps';

import {lazy, Suspense} from 'react';

import {IconSet} from '~/constants/Icons';
import Loader from '~/components/common/Loader';

function loadIcon(mode: string, ico: string) {
    if (mode === 'auto') {
        mode = Object.keys(IconSet).reduce(
            (mode, set) => {
                if (!mode && IconSet[set].includes(ico)) {
                    mode = set;
                }
                return mode;
            },
            ''
        );
        if (!mode) {
            throw new Error(`Can't find mode for icon ${ico}`);
        }
    }
    const icoFile = ico + capitalize(mode);

    return {
        IconComponent: lazy(() => import(`../../../node_modules/@ant-design/icons/es/icons/${icoFile}.js`)),
        ico,
        mode
    };
}

function Icon({
    title: propsTitle,
    ico: propsIco,
    mode: propsMode = 'auto',
    rotate = 0,
    spin = false,
    twoToneColor,
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName
}: IconProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const {IconComponent, ico, mode} = loadIcon(propsMode, propsIco);
    return <span
        title={propsTitle !== undefined ? (propsTitle === '' ? undefined : propsTitle) : `${ico} ${mode}`}
        className={classNames(
            classPrefix('icon'),
            propsClassName,
            styles('icon', `icon-${ico}`)
        )}
    >
        <Suspense fallback={<Loader />}>
            <IconComponent
                rotate={rotate}
                spin={spin}
                twoToneColor={twoToneColor}
            />
        </Suspense>
    </span>;
}
export default Icon;
